import {
  addDays,
  addMonths,
  endOfMonth,
  getUnixTime,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { flatMap, isEmpty, isNil, round } from 'lodash';
import {
  dateRangePagination,
  factsAvgMetrics,
  factsMetrics,
  metricChartType,
  requestMetrics,
  requestYearMetrics,
  simpleRequestMetrics,
  sleepDepthLevels,
} from '@constants/generatedData';
import { ChartType, DateRange, Metric, MetricState, RequestMetric } from 'models/generatedData';
import { ActivityDay } from 'models/activities';
import { getCurrentUnitSystem } from 'utils/staticUnitSystem';

export const getRequestMetricArray = (metric: Metric, dateRange: DateRange): RequestMetric[] => {
  if (metric === 'band_removed' && dateRange === 1) {
    return ['band_removed'];
  }

  return simpleRequestMetrics[metric];
};

export const getChartType = (metric: Metric, data: any, dateRange: DateRange): ChartType => {
  if (metricChartType[metric] === 'basicLine') {
    if (dateRange === 1 && data[metric] && data[metric].length === 1) {
      return 'onePointLine';
    }

    if (
      dateRange !== 1 &&
      data.general &&
      data.general.filter((item: any) => !isEmpty(item.data) && !isNil(item.data[`${metric}_mean`]))
        ?.length === 1
    ) {
      return 'onePointLine';
    }
  }

  if (metric === 'temp') {
    if (dateRange === 1 && data.skin_temp && data.skin_temp.length === 1) {
      return 'onePointLine';
    }

    if (
      dateRange !== 1 &&
      data.general &&
      data.general.filter((item: any) => !isEmpty(item.data) && !isNil(item.data.skin_temp_mean))
        ?.length === 1
    ) {
      return 'onePointLine';
    }
  }

  if (metric === 'band_removed' && dateRange !== 1) {
    return 'composedBar';
  }

  if (metric === 'sleep' && dateRange !== 1) {
    if (
      data.general &&
      data.general.filter(
        (item: any) => !isEmpty(item.data) && !isNil(item.data.sleep_efficiency_mean)
      )?.length === 1
    ) {
      return 'onePointLine';
    }
    return 'sleepLine';
  }

  return metricChartType[metric];
};

export const getRequestInfo = (metrics: RequestMetric[], dateRange: DateRange): any =>
  dateRange === 1
    ? flatMap(
        metrics.map((item) =>
          item === 'spo2'
            ? 'spo2%3D80'
            : item === 'weight'
            ? ['weight%3D-1', 'weight_conf']
            : item === 'heart_rate'
            ? ['heart_rate', 'low_heart_rate']
            : item
        )
      )
    : dateRange === 365
    ? flatMap(metrics.map((item) => requestYearMetrics[item]))
    : flatMap(metrics.map((item) => requestMetrics[item]));

export const getFactsRequestInfo = (
  metrics: Metric[],
  secondaryMetrics: Metric[],
  dateRange: number,
  startTime: number,
  endTime: number
): any => {
  const metricsFacts = flatMap(
    metrics.map((metric: Metric) => {
      if (['meals', 'medications', 'workouts', 'band_removed'].includes(metric)) {
        return [];
      } else if (metric === 'insulin') {
        const facts = dateRange === 1 ? ['bolus_sum', 'basal_sum'] : ['bolus_mean', 'basal_mean'];
        return facts?.map((fact: string) => `${fact}_from_${startTime}_to_${endTime}`);
      } else {
        const facts = metrics.length === 2 ? factsAvgMetrics : factsMetrics;
        return facts[metric]?.map((fact: string) => `${fact}_from_${startTime}_to_${endTime}`);
      }
    })
  );

  const secMetricsFacts = flatMap(
    secondaryMetrics.map((metric: Metric) => {
      if (['meals', 'medications', 'workouts', 'band_removed'].includes(metric)) {
        return [];
      } else if (metric === 'insulin') {
        const facts = dateRange === 1 ? ['bolus_sum', 'basal_sum'] : ['bolus_mean', 'basal_mean'];
        return facts?.map((fact: string) => `${fact}_from_${startTime}_to_${endTime}`);
      } else {
        const facts = secondaryMetrics.length === 2 ? factsAvgMetrics : factsMetrics;
        return facts[metric]?.map((fact: string) => `${fact}_from_${startTime}_to_${endTime}`);
      }
    })
  );

  return [...metricsFacts, ...secMetricsFacts];
};

export const getMetricState = (metrics: Metric[], data: any, dateRange: DateRange): MetricState => {
  let metricsState: any = {};
  metrics.forEach((metric: Metric) => {
    if (dateRange === 1) {
      switch (metric) {
        case 'calories':
        case 'steps':
          metricsState[metric] = data[metric]?.some(
            (item: any) => item.data[`${metric}_sum`] !== 0
          );
          break;
        case 'rhr':
          metricsState.rhr = data?.rhr?.some((item: any) => item.data?.rhr_mean !== 0);
          break;
        case 'insulin':
          metricsState.insulin =
            data?.bolus?.some((item: any) => item.data?.bolus_sum !== 0) ||
            data?.basal?.some((item: any) => item.data?.basal_sum !== 0);
          break;
        case 'temp':
          metricsState.temp = !isEmpty(data?.ambient_temp) || !isEmpty(data?.skin_temp);
          break;
        case 'blood_pressure':
          metricsState.blood_pressure = !isEmpty(data?.systolic) || !isEmpty(data?.diastolic);
          break;
        case 'meals':
          metricsState.meals = data?.meals?.some((item: ActivityDay) => !isEmpty(item.activities));
          break;
        case 'medications':
          metricsState.medications = data?.medications?.some(
            (item: ActivityDay) => !isEmpty(item.activities)
          );
          break;
        case 'workouts':
          metricsState.workouts = data?.workouts?.some(
            (item: ActivityDay) => !isEmpty(item.activities)
          );
          break;
        case 'gsr':
        case 'battery_level':
          metricsState[metric] = !isEmpty(data[metric] || []);
          break;
        case 'band_removed':
          metricsState.band_removed = !isEmpty(data?.band_removed || []);
          break;
        default:
          metricsState[metric] = (data[metric] || []).some((item: any) => item[1] !== 0);
      }
    } else {
      switch (metric) {
        case 'meals':
          metricsState.meals = data?.meals?.some((item: ActivityDay) => !isEmpty(item.activities));
          break;
        case 'medications':
          metricsState.medications = data?.medications?.some(
            (item: ActivityDay) => !isEmpty(item.activities)
          );
          break;
        case 'workouts':
          metricsState.workouts = data?.workouts?.some(
            (item: ActivityDay) => !isEmpty(item.activities)
          );
          break;
        case 'sleep':
          const sleepData = data?.general?.filter(
            (item: any) => 'sleep_efficiency_mean' in item.data
          );
          metricsState.sleep = sleepData?.some(
            (item: any) => item.data?.sleep_efficiency_mean !== 0
          );
          break;
        case 'calories':
        case 'steps':
          const stepsData = data?.general?.filter(
            (item: any) => !('sleep_efficiency_mean' in item.data)
          );
          metricsState[metric] =
            dateRange === 365
              ? stepsData?.some((item: any) => item.data[`${metric}_mean`])
              : stepsData?.some((item: any) => item.data[`${metric}_sum`]);
          break;
        case 'insulin':
          const insulinData = data?.general?.filter(
            (item: any) => !('sleep_efficiency_mean' in item.data)
          );
          metricsState.insulin =
            dateRange === 365
              ? insulinData?.some(
                  (item: any) => !isNil(item.data?.bolus_mean) && item.data?.bolus_mean !== 0
                ) ||
                insulinData?.some(
                  (item: any) => !isNil(item.data?.basal_mean) && item.data?.basal_mean !== 0
                )
              : insulinData?.some(
                  (item: any) => !isNil(item.data?.bolus_sum) && item.data?.bolus_sum !== 0
                ) ||
                insulinData?.some(
                  (item: any) => !isNil(item.data?.basal_sum) && item.data?.basal_sum !== 0
                );
          break;
        case 'temp':
          const tempData = data?.general?.filter(
            (item: any) => !('sleep_efficiency_mean' in item.data)
          );
          metricsState.temp =
            tempData?.some(
              (item: any) =>
                !isNil(item.data?.ambient_temp_mean) && item.data?.ambient_temp_mean !== 0
            ) ||
            tempData?.some(
              (item: any) => !isNil(item.data?.skin_temp_mean) && item.data?.skin_temp_mean !== 0
            );
          break;
        case 'blood_pressure':
          const bpData = data?.general?.filter(
            (item: any) => !('sleep_efficiency_mean' in item.data)
          );
          metricsState.blood_pressure = bpData?.some(
            (item: any) => !isNil(item.data?.systolic_mean) && item.data?.systolic_mean !== 0
          );
          break;
        case 'band_removed':
          const bandData = data?.general?.filter(
            (item: any) => !('sleep_efficiency_mean' in item.data)
          );
          metricsState.band_removed =
            dateRange === 365
              ? bandData?.some(
                  (item: any) =>
                    !isNil(item.data?.band_removed_time_mean) &&
                    item.data?.band_removed_time_mean !== 0
                ) ||
                bandData?.some(
                  (item: any) =>
                    !isNil(item.data?.band_charge_time_mean) &&
                    item.data?.band_charge_time_mean !== 0
                ) ||
                bandData?.some(
                  (item: any) =>
                    !isNil(item.data?.band_wearing_time_mean) &&
                    item.data?.band_wearing_time_mean !== 0
                )
              : bandData?.some(
                  (item: any) =>
                    !isNil(item.data?.band_removed_time) && item.data?.band_removed_time !== 0
                ) ||
                bandData?.some(
                  (item: any) =>
                    !isNil(item.data?.band_charge_time) && item.data?.band_charge_time !== 0
                ) ||
                bandData?.some(
                  (item: any) =>
                    !isNil(item.data?.band_wearing_time) && item.data?.band_wearing_time !== 0
                );
          break;
        default:
          const metricData = data?.general?.filter(
            (item: any) => !('sleep_efficiency_mean' in item.data)
          );
          metricsState[metric] = metricData?.some(
            (item: any) => !isNil(item.data[`${metric}_mean`]) && item.data[`${metric}_mean`] !== 0
          );
      }
    }
  });
  return metricsState;
};

export const getTimezoneDate = (
  dateRange: DateRange,
  tz: string,
  dateDiff: any,
  withNext: boolean = true
) => {
  const timezonesOffset = getGlobalTimezoneOffset(tz);
  const today = addDays(startOfDay(utcToZonedTime(new Date(), tz)), 1);
  let startTime;
  let endTime;

  if (dateRange === 365) {
    startTime =
      getUnixTime(startOfMonth(subMonths(today, 11 - dateDiff / dateRangePagination[dateRange]))) -
      timezonesOffset;
    endTime =
      getUnixTime(endOfMonth(addMonths(today, dateDiff / dateRangePagination[dateRange]))) -
      timezonesOffset +
      (withNext ? 1 : 0);
  } else {
    startTime = getUnixTime(subDays(today, dateRange - dateDiff)) - timezonesOffset;
    endTime = getUnixTime(addDays(today, dateDiff)) - timezonesOffset - (withNext ? 0 : 1);
  }

  return {
    startTime,
    endTime,
  };
};

export const getYAxisValue = (value: number, dateRange: DateRange) =>
  value / 1000 > 1 && dateRange === 365 ? `${round(value / 1000, 2)}K` : `${round(value, 2)}`;

export const getGlobalTimezoneOffset = (tz: string) =>
  new Date().getTimezoneOffset() * 60 + getTimezoneOffset(tz) / 1000;

export const getConvertedTime = (time12h: string) => {
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier.toLowerCase() === 'pm') {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours}:${minutes}`;
};

export const getConvertedValue = (value: number, metric: Metric | string) => {
  const unitSystem = getCurrentUnitSystem();
  switch (metric) {
    case 'spo2':
      return value * 100;
    case 'weight':
      return typeof value === 'number' ? unitSystem.localeWeight(value) : value;

    case 'temp':
    case 'ambient_temp':
    case 'skin_temp':
      return typeof value === 'number' ? unitSystem.localeTemperature(value) : value;
    default:
      return value;
  }
};

export const getSleepFromSleepDepth = (sleepDepth: any) => {
  if (!isEmpty(sleepDepth)) {
    const validValues = Object.keys(sleepDepthLevels);
    const firstIndex = sleepDepth.findIndex(
      (el: any) => validValues.includes(el[1].toString()) || el[1] > 4
    );
    if (firstIndex !== -1) {
      const [firstTimestamp, firstValue] = sleepDepth[firstIndex];
      const initialValue = [firstTimestamp, firstTimestamp, Number(firstValue)];

      return sleepDepth
        .reduce(
          (mappedData: any, currentTick: any, index: number) => {
            const lastIndex = mappedData.length - 1;
            const previousValue = mappedData[lastIndex][2];
            const [currentTickTimestamp, currentTickValue] = currentTick;
            if (sleepDepth.length - 1 === index) {
              mappedData[lastIndex][1] = currentTickTimestamp;
            }
            if (index >= firstIndex && previousValue !== currentTickValue) {
              mappedData[lastIndex][1] = currentTickTimestamp;
              const newSegment = [
                currentTickTimestamp,
                currentTickTimestamp,
                Number(currentTickValue) > 4 ? 4 : Number(currentTickValue),
              ];
              mappedData.push(newSegment);
            }
            return mappedData;
          },
          [initialValue]
        )
        .filter((el: any) => validValues.includes(el[2].toString()));
    }
  }

  return [];
};
